import * as React from 'react';
import {StyledCardAbout} from './styles';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';

const CardAbout = ({img, background, name}) => {
  return (
    <StyledCardAbout background={background}>
      <div>
        <div>
          <img src={img}></img>
        </div>
      </div>
      <Text>{name}</Text>
    </StyledCardAbout>
  );
};

export default CardAbout;

CardAbout.propTypes = {
  img: PropTypes.element,
  background: PropTypes.element,
  name: PropTypes.string,
};
