import React from 'react';
import {navigate} from 'gatsby';
import SliderSlick from 'react-slick';
import {Hidden} from 'react-awesome-styled-grid';

import {CollectionsStyled} from './styles';
import SliderCollections from '../core/SliderCollections';
import PropTypes from 'prop-types';

export const SliderStatic = ({banner}) => {
  const settings = {
    className: 'center',
    dots: true,
    infinite: true,
    centerPadding: '40px',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    rtl: false,
    arrows: true,
  };

  return (
    <CollectionsStyled>
      <link
        rel="stylesheet"
        type="text/css"
        // charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Hidden xs>
        <SliderSlick {...settings}>
          <SliderCollections
            key={banner?.id}
            tag="collections-banner"
            image={banner?.image?.publicUrl}
            onClickImage={() => navigate(`${banner.url}`)}
          />
        </SliderSlick>
      </Hidden>
      <Hidden sm md lg xl>
        <SliderSlick {...settings}>
          <SliderCollections
            key={banner?.id}
            tag="collections-banner"
            image={banner?.mobileImage?.publicUrl}
            onClickImage={() => navigate(`${banner?.url}`)}
          />
        </SliderSlick>
      </Hidden>
    </CollectionsStyled>
  );
};

SliderStatic.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.shape({
      publicUrl: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
      publicUrl: PropTypes.string,
    }),
    headline: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
  }),
};
export default SliderStatic;
