import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';
import {InstagramStyle} from './styles';
import {ElfsightWidget} from 'react-elfsight-widget';

const Instagram = () => {
  return (
    <InstagramStyle>
      <Row>
        <Col>
          <ElfsightWidget widgetID="3e3acf84-5965-498a-ba6d-6da0c923e8c7" />
        </Col>
      </Row>
    </InstagramStyle>
  );
};

export default Instagram;
