import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import {Col, Hidden, Row} from 'react-awesome-styled-grid';

import Card from '../core/Card';
import {ProductStyle} from './styles';
import SkeletonProducts from './SkeletonProducts';
import useImgixImage from '../../hooks/useImgixImage';
import {fetchProductsWithFilter} from '../../API/makers/products';

const Product = ({titleSection, tagFilter}) => {
  const imgEmptyState = useImgixImage('/images/empty-state.png');
  const logoEmpty = useImgixImage('/images/logo-empty.png');
  const {products, isLoading} = fetchProductsWithFilter(`${tagFilter}`);
  if (isLoading) {
    return <SkeletonProducts />;
  }
  const isActive = products.filter(item => item.active === true);
  return (
    <ProductStyle>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            {titleSection}
          </Heading>
        </Col>
      </Row>
      <Row justify="center">
        {!products.length && !isLoading ? (
          <Col align="center">
            <img src={logoEmpty} className="EmptyState" />
          </Col>
        ) : (
          isActive.map(product => (
            <Row key={product.id} className="wrap__cards">
              <Card
                productId={product.id}
                img={
                  product.master.images[0]
                    ? product.master.images[0].product_url
                    : imgEmptyState
                }
                productName={product.name}
                product={product}
                Price={product.master.display_price}
              ></Card>
            </Row>
          ))
        )}
      </Row>
      <Row justify="center">
        <Hidden sm md lg xl>
          <Link to={'/all-shoes'}>
            <button className="buttonMobile">
              <Link to={'/all-shoes'}>
                <Text level="5" width="92px" height="30px">
                  SEE ALL +
                </Text>
              </Link>
            </button>
          </Link>
        </Hidden>
      </Row>
    </ProductStyle>
  );
};

export default Product;

Product.propTypes = {
  tagFilter: PropTypes.string,
  titleSection: PropTypes.string,
};
