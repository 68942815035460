import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const PopUpStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 400px;
  flex-direction: row;
  overflow: hidden;
  background: black;
  cursor: pointer;
  ${props => config(props).media['sm']`
    width: 600px;
  `}

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;

    filter: invert(100%) brightness(200%);
    float: right;
    cursor: pointer;
    :hover {
      filter: invert(80%) brightness(100%);
    }
  }
`;

export const ImagePopUp = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 400px;
  flex-direction: row;
  overflow: hidden;
  background: black;
  background-size: cover;
  background-image: url(${({image2}) => image2});
  cursor: pointer;
  ${props => config(props).media['sm']`
    width: 600px;
    background-image: url(${({image1}) => image1});
  `}
`;
