import React from 'react';

import {PopUpStyled, ImagePopUp} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import {useGlobalContext} from '../../context';
import {graphql, navigate, useStaticQuery} from 'gatsby';

const ModalBannerQuery = graphql`
  query ModalBannerImage {
    contentfulBannerImage(id: {}, internalName: {eq: "home-popup"}) {
      url
      mobileImage {
        publicUrl
        mimeType
        filename
      }
      image {
        publicUrl
        mimeType
        filename
      }
      headline
      description {
        raw
      }
    }
  }
`;
const PopUp = () => {
  const {contentfulBannerImage: popup} = useStaticQuery(ModalBannerQuery);
  const iconClose = useImgixImage('/images/close-icon.svg');
  const {setModalPopupsIsOpen} = useGlobalContext();

  return (
    <PopUpStyled>
      <img
        className="close-modal"
        src={iconClose}
        onClick={() => setModalPopupsIsOpen(false)}
      />
      <ImagePopUp
        image1={popup?.image?.publicUrl}
        image2={popup?.mobileImage?.publicUrl}
        onClick={() => navigate(popup?.url)}
      />
    </PopUpStyled>
  );
};

export default PopUp;
