import React from 'react';
import {Col, Container, Hidden, Row} from 'react-awesome-styled-grid';
import {Heading, Text} from 'styled-typography';
import {MoreProductsStyle} from './styles';

import Skeleton from 'react-loading-skeleton';
import ButtonProduct from '../core/ButtonProduct';

const SkeletonMoreProducts = () => {
  return (
    <Container>
      <MoreProductsStyle>
        <Row>
          <Col>
            <Heading as="h3" level="4">
              Explore more products
              <ButtonProduct>SEE ALL +</ButtonProduct>
            </Heading>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col align="center">
            <Hidden xs sm>
              <Skeleton className="skeletonMoreProductStyle" count={12} />
            </Hidden>
            <Hidden md lg xl>
              <Skeleton className="skeletonMoreProductStyle" count={12} />
            </Hidden>
          </Col>
        </Row>
        <Row justify="center">
          <button className="buttonMobile">
            <Text level="5">SEE ALL +</Text>
          </button>
        </Row>
      </MoreProductsStyle>
    </Container>
  );
};

export default SkeletonMoreProducts;
