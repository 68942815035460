import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const StyledCardAbout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    border-radius: 51%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({theme}) => theme.colors.primary2};
    width: 90px;
    height: 90px;
    ${props => config(props).media['md']`
      width: 132px;
      height: 132px;
    `}
    div {
      border: none;
      background: white;
      width: 72.95px;
      height: 72.95px;
      ${props => config(props).media['md']`
        width: 107px;
        height: 107px;
      `}
        > img {
          width: 50%;
          height: 50%;
        }
      }
    }
  }

  p {
    color: black;
    margin-top: 10px;
    ${props => config(props).media['md']`
      margin-top: 20px;
    `}
  }
`;
