import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const ClientStyled = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  width: 100%;
  height: 300px;
  ${props => config(props).media['sm']`
    height: 400px;
  `}
  ${props => config(props).media['md']`
    height: 600px;
  `}
  
  .clip-section-video {
    background-color: black;
    margin-top: 20px;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => config(props).media['sm']`
      margin-top: 40px;
      margin-bottom: -40px;
    `}
    video {
      z-index: 1;
      object-fit: cover;
      top: 0;
      left: 0;
      width: 100%;

      height: 300px;
      ${props => config(props).media['sm']`
        height: 400px;
      `}
      ${props => config(props).media['md']`
        height: 600px;
      `}
    }
  }
`;
