import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

import arrowBotton from '../../images/arrow-botton-right.png';

export const BannerStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  object-fit: cover;
  button.slick-next {
    display: none;
    z-index: 1;
    position: absolute;
    background: url(${({arrow}) => arrow === 'true' && arrowBotton}) center
      center no-repeat;
    transform: rotate(180deg);
    top: 51.6%;
    ${props => config(props).media['sm']`
      display: flex;
      height: 0px;
      width: 0px;
      right: 25%;
    `}
    ${props => config(props).media['md']`
      height: 40px;
      width: 40px;
    `}
    ${props => config(props).media['lg']`
      right: 25%;
    `}
  }
  button:before {
    display: none;
  }
  button.slick-prev {
    display: none;
    z-index: 1;
    position: absolute;
    background: url(${({arrow}) => arrow === 'true' && arrowBotton}) center
      center no-repeat;
    top: 54.3%;
    ${props => config(props).media['sm']`
      display: flex;
      height: 0px;
      width: 0px;
      left: 25%;
    `}
    ${props => config(props).media['md']`
      height: 40px;
      width: 40px;
    `}
    ${props => config(props).media['lg']`
      left: 25%;
    `}
  }
  .slick-dots {
    z-index: 0;
    margin-bottom: 52px;
    justify-content: center;
    display: flex;
  }
  .slick-dots button:before {
    font-size: 16px;
    color: white;
    width: 10px;
    height: 10px;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    font-size: 16px;
    opacity: 0.75;
    color: transparent;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 3px;
    width: 10px;
    height: 10px;
  }
`;
