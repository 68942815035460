import {Container} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const StyledButtonProduct = styled.button`
  ${Container} {
    background-color: red;
  }
  width: ${({width}) => width};
  height: ${({height}) => height};

  background-color: ${({theme}) => theme.colors.primary2};
  padding: 0;
  border: none;
  border-radius: 6px;
  margin-bottom: 10px;
  outline: none;
  color: white;
  cursor: pointer;

  p {
    color: white;
    font-weight: 600;
    font-size: 13px;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.primary3};
  }

  &:active {
    outline: none;
    background-color: #c29f5e;
    p {
      color: white;
    }
  }

  &:disabled {
    background-color: ${({theme}) => theme.colors.secondary};
    cursor: default;
  }
`;
