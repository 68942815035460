import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const StyledAbout = styled.div`
  flex-direction: column;
  margin: 60px 20px;
  ${props => config(props).media['md']`
    margin: 90px 60px;
  `}
  h5 {
    margin-bottom: 25px;
  }

  h3 {
    color: ${({theme}) => theme.colors.primary2};
    letter-spacing: 0px;
    font-size: ${({theme}) => theme.typography.fontSizes[3]};
    ${props => config(props).media['md']`
    letter-spacing: 1.5px;
    font-size: ${({theme}) => theme.typography.fontSizes[5]};
    `}
  }

  hr {
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.secondary2};
  }

  .container-text {
    margin-top: 25px;
    margin-bottom: 0px;
    ${props => config(props).media['md']`
    margin-top: 69px;
    margin-bottom: 80px;
    `}
    >div {
      h4 {
        margin-bottom: 25px;
        ${props => config(props).media['md']`
        margin-bottom: 25px;
        `}
      }
      p {
        line-height: 20px;
        letter-spacing: -0.4px;
        font-size: ${({theme}) => theme.typography.fontSizes[3]};
        margin-left: 0px;
        ${props => config(props).media['lg']`
          letter-spacing: 0.1px;
          margin-left: -10px;
          font-size: ${({theme}) => theme.typography.fontSizes[4]};
        `}
      }
    }
  }

  .card-about {
    margin-top: 41px;
    ${props => config(props).media['md']`
      margin-top: 0px;
    `}
  }
`;
