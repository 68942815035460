import styled from 'styled-components';

export const InstagramStyle = styled.div`
  width: 100%;
  height: 100%;
  background: none;

  .card {
    width: 18rem;
  }
`;
