import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';
import {ClientStyled} from './styles';
import coverVideo from '../../images/Banners/cover-video.png';
import {graphql, useStaticQuery} from 'gatsby';

const VideoStaticQuery = graphql`
  query LookBookBannerVideo {
    contentfulBannerVideo(internalName: {eq: "home-makers-shoes-video"}) {
      image {
        publicUrl
        mimeType
        filename
      }
      internalName
    }
  }
`;

export const SliderComponent = () => {
  const {contentfulBannerVideo: banner} = useStaticQuery(VideoStaticQuery);

  return (
    <ClientStyled>
      {
        <Row>
          <Col className="clip-section-video">
            <video
              muted
              autoPlay={true}
              loop={false}
              controls
            >
              <source
                src={banner?.image?.publicUrl}
                type={banner?.image?.mimeType}
              />
            </video>
          </Col>
        </Row>
      }
    </ClientStyled>
  );
};

export default SliderComponent;
