import React from 'react';
import PropTypes from 'prop-types';
import {Heading} from 'styled-typography';
import {Col, Row} from 'react-awesome-styled-grid';

import {CategoryStyle} from './styles';
import SliderCategory from './sliderCategory';
import SkeletonCategory from './SkeletonCategory';

const Category = ({title, category, taxons, onFilterStyle, isLoading}) => {
  if (isLoading) {
    return <SkeletonCategory />;
  }
  return (
    <CategoryStyle taxonsTag={taxons}>
      {title && (
        <Row>
          <Col>
            <Heading as="h3" level="4">
              {title}
            </Heading>
          </Col>
        </Row>
      )}
      <Row justify="center">
        <SliderCategory
          images={category}
          taxons={taxons}
          onFilterStyle={onFilterStyle}
        />
      </Row>
    </CategoryStyle>
  );
};

export default Category;

Category.propTypes = {
  img: PropTypes.element,
  background: PropTypes.element,
  title: PropTypes.string,
  category: PropTypes.object,
  taxons: PropTypes.array,
  onFilterStyle: PropTypes.func,
  isLoading: PropTypes.bool,
};
