import * as React from 'react';
import Modal from 'react-modal';
import PopUp from '../../PopUp';
import About from '../../About';
import Banner from '../../DynamicBanner/slidershow';
import Product from '../../Product';
import Category from '../../Category';
import Instagram from '../../Instagram';
import Collections from '../../Collections';
import ReviewClient from '../../ReviewClient';
import MoreProducts from '../../MoreProducts';
import BannerLookBook from '../../BannerLookBook';
import {useGlobalContext} from '../../../context';
import {fetchTaxons} from '../../../API/makers/taxons';
import useImgixImage from '../../../hooks/useImgixImage';
import {graphql, useStaticQuery} from 'gatsby';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: 'auto',
    color: 'black',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    padding: '0x',
  },
  overlay: {
    zIndex: '',
  },
};

const HomePageQuery = graphql`
  query HomePage {
    contentfulSections(internalName: {eq: "Homepage-Hero"}) {
      id
      internalName
      banners {
        image {
          publicUrl
          mimeType
          filename
          url
        }
        description {
          raw
        }
        headline
        mobileImage {
          publicUrl
          mimeType
          filename
        }
      }
    }
  }
`;

const Default = () => {
  const {contentfulSections: homePageHero} = useStaticQuery(HomePageQuery);
  const {taxons, isLoading} = fetchTaxons();
  const {setFilterStyle} = useGlobalContext();
  const sandals = useImgixImage('/images/sandals-img.png');
  const sneakers = useImgixImage('/images/sneakers-img.png');
  const flats = useImgixImage('/images/flats-img.png');
  const heels = useImgixImage('/images/heels-img.png');
  const wedges = useImgixImage('/images/wedges-img.png');
  const boots = useImgixImage('/images/boots-img.png');
  const imgEmpty = useImgixImage('/images/no-image.png');

  const CATEGORY = {
    sandals: {
      img: sandals,
    },
    sneakers: {
      img: sneakers,
    },
    flats: {
      img: flats,
    },
    heels: {
      img: heels,
    },
    wedges: {
      img: wedges,
    },
    boots: {
      img: boots,
    },
    imgEmpty: {
      img: imgEmpty,
    },
  };

  const tagBannerMain = 'home-main-banner';
  const tagBannerLBHome = 'home-lookbook-banner';
  const {modalPopupsIsOpen, setModalPopupsIsOpen} = useGlobalContext();

  const handleFilterStyle = filter => {
    setFilterStyle(filter);
  };

  const openModal = () => {
    setModalPopupsIsOpen(true);
  };

  const closeModal = () => {
    setModalPopupsIsOpen(false);
  };

  const handleOpenModal = () => {
    setTimeout(() => {
      openModal();
    }, 8000);
  };

  React.useEffect(() => {
    handleOpenModal();
  }, []);

  const sectionsCards = key => {
    return (
      <>
        <Product
          titleSection="Best Seller Products"
          tagFilter={`q[best_seller_eq]=true&per_page=${key}&order_by=created_at_desc`}
        />
        <Product
          titleSection="Trending Products"
          tagFilter={`q[trending_true]=true&per_page=${key}&order_by=created_at_desc`}
        />
      </>
    );
  };

  const showCards = () => {
    return <>{sectionsCards('6')}</>;
  };

  return (
    <>
      <Modal
        isOpen={modalPopupsIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <PopUp />
      </Modal>

      <Banner
        tagName={tagBannerMain}
        arrow="false"
        banners={homePageHero.banners}
      />
      <Category
        title="Explore Our Categories"
        taxons={taxons}
        category={CATEGORY}
        onFilterStyle={handleFilterStyle}
        isLoading={isLoading}
      />
      {showCards()}
      <Collections />
      <MoreProducts />
      <BannerLookBook tagName={tagBannerLBHome} />
      <Instagram />
      <ReviewClient />
      <About />
    </>
  );
};

export default Default;
