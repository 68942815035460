import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import {Col, Row} from 'react-awesome-styled-grid';

import {BannerLookBookStyle} from './styles';
import {parseRichText} from '../../utils/staticContent';

const LookBookHomeBanner = graphql`
  query LookBookBannerImage {
    contentfulBannerImage(
      id: {}
      internalName: {eq: "home-lookbook-hero-banner-1"}
    ) {
      url
      mobileImage {
        publicUrl
        mimeType
        filename
      }
      image {
        publicUrl
        mimeType
        filename
      }
      headline
      description {
        raw
      }
    }
  }
`;
const BannerLookBook = () => {
  const {contentfulBannerImage: banner} = useStaticQuery(LookBookHomeBanner);
  return (
    <Link to={banner?.url}>
      <BannerLookBookStyle>
        <Row className="container-banner">
          <Col className="container-banner-image">
            <img src={banner?.image?.publicUrl} />
          </Col>
          <Col className="container-banner-info">
            <Text as="h2">{banner?.headline}</Text>
            <Text as="h3">{parseRichText(banner?.description?.raw)}</Text>
          </Col>
        </Row>
      </BannerLookBookStyle>
    </Link>
  );
};

export default BannerLookBook;

BannerLookBook.propTypes = {
  tagName: PropTypes.string,
};
