import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';
import {FontWeight, Heading, Text} from 'styled-typography';

import {StyledAbout} from './styles';
import CardAbout from '../core/CardAbout';
import useImgixImage from '../../hooks/useImgixImage';

const About = () => {
  const stylesPerYear = useImgixImage('/images/icon/stylesPerYear.svg');
  const firstClassMaterial = useImgixImage(
    '/images/icon/firstClassMaterial.svg'
  );
  const productions = useImgixImage('/images/icon/productions.svg');
  const prices = useImgixImage('/images/icon/prices.svg');

  const ABOUTSCARDS = [
    {
      img: stylesPerYear,
      title: '+1k Styles per year',
    },
    {
      img: firstClassMaterial,
      title: 'Current trends',
    },
    {
      img: productions,
      title: 'Leading in production time',
    },
    {
      img: prices,
      title: 'Designed to sale',
    },
  ];

  return (
    <StyledAbout>
      <Row>
        <Col xs="2.4" sm="2.5" lg="2" xl="1.5">
          <Heading as="h3" fontWeight={FontWeight.Bold}>
            ABOUT MAKERS VALUES
          </Heading>
        </Col>
        <Col xs="1.6" sm="5.5" lg="10" xl="10.5" justify="center">
          <Text>
            <hr />
          </Text>
        </Col>
      </Row>
      <Row className="container-text">
        <Col xs="4" lg="6.2">
          <Heading as="h4" className="head4-normal-medium">
            Maker’s Shoes offers fast and current fashion that goes with the
            trend of the moment.
          </Heading>
        </Col>
        <Col xs="4" lg="5.8" justify="center">
          <Text as="h5" level="5">
            Family-owned and operated, Maker’s Shoes has specialized in the
            production of women’s footwear since 2005. We manufacture in
            Colombia, Brazil and Mexico. Our number one priority is to offer
            varied styles that allow our clients to generate sales.
          </Text>
        </Col>
      </Row>
      <Row justify="center">
        {ABOUTSCARDS.map((abouts, index) => (
          <Col xs="2" md="2" lg="2.8" key={index} className="card-about">
            <CardAbout img={abouts.img} name={abouts.title} />
          </Col>
        ))}
      </Row>
    </StyledAbout>
  );
};

export default About;
