import React from 'react';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import SliderSlick from 'react-slick';

import Slider from '../core/Slider';
import {BannerStyled} from './styles';
import {Hidden} from 'react-awesome-styled-grid';
import SkeletonSlider from '../core/Slider/SkeletonSlider';

export const SliderComponent = ({banners, arrow, tagName}) => {
  const settings = {
    dots: false,
    infinite: true,
    centerPadding: '400px',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    adaptiveHeight: false,
    pauseOnHover: false,
    autoplaySpeed: 4500,
    rtl: false,
    arrows: arrow,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <BannerStyled arrow={arrow}>
      <link
        rel="stylesheet"
        type="text/css"
        // charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Hidden xs>
        <SliderSlick {...settings}>
          {banners.length ? (
            banners.map(banner =>
              tagName !== 'collections-banner' ? (
                <Slider
                  key={banner.id || ''}
                  tag={tagName}
                  title={banner.headline || ''}
                  image={banner.image.publicUrl}
                  description={banner.description || ''}
                  titleButton={banner.title_button || ''}
                  onClick={banner.url ? () => navigate(`${banner.url}`) : ''}
                />
              ) : (
                <Slider
                  key={banner.id || ''}
                  tag={tagName}
                  image={banner.image.publicUrl}
                  // onClick={banner.link ? () => navigate(`${banner.link}`) : ''}
                />
              )
            )
          ) : (
            <SkeletonSlider tag={tagName} />
          )}
        </SliderSlick>
      </Hidden>
      <Hidden sm md lg xl>
        <SliderSlick {...settings}>
          {banners.length ? (
            banners.map(banner =>
              tagName !== 'collections-banner' ? (
                <Slider
                  key={banner.id}
                  tag={tagName}
                  title={banner.headline}
                  description={banner.description}
                  image={banner.mobileImage.publicUrl}
                  titleButton={banner.title_button}
                  onClick={() => navigate(`${banner.url}`)}
                />
              ) : (
                <Slider
                  key={banner.id || ''}
                  tag={tagName}
                  image={banner.mobileImage.publicUrl}
                  // onClick={banner.link ? () => navigate(`${banner.link}`) : ''}
                />
              )
            )
          ) : (
            <SkeletonSlider tag={tagName} />
          )}
        </SliderSlick>
      </Hidden>
    </BannerStyled>
  );
};

export default SliderComponent;

SliderComponent.propTypes = {
  tagName: PropTypes.string,
  arrow: PropTypes.bool,
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.shape({
        publicUrl: PropTypes.string,
      }),
      mobileImage: PropTypes.shape({
        publicUrl: PropTypes.string,
      }),
      headline: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};
