import styled from 'styled-components';

export const BannerLookBookStyle = styled.div`
  .container-banner {
    display: flex;
    flex-direction: column;
    position: relative;
    .container-banner-image {
      img {
        z-index: -100;
        width: 100%;
        height: 100%;
      }
    }
    .container-banner-info {
      display: flex;
      z-index: 100;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      width: 100%;
      /* height: 100%; */
      h2 {
        display: flex;
        font-size: 62px;
        color: #fff;
        border-bottom: 1px solid #fff;
        font-weight: bold;
        align-items: center;
      }
      h3 {
        display: flex;
        font-size: 20px;
        color: #fff;
        align-items: center;
      }
    }
  }
`;
