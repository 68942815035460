import React from 'react';
import {ClientStyled} from './styles';
import SliderComponent from './slidershow';

const ReviweClient = () => {
  return (
    <ClientStyled>
      <SliderComponent />
    </ClientStyled>
  );
};

export default ReviweClient;
