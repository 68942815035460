import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';
import arrowBotton from '../../images/arrow-botton-right.png';

export const CategoryStyle = styled.div`
  margin-top: ${({taxonsTag}) => (taxonsTag ? '60px' : '20px')};
  margin-bottom: ${({taxonsTag}) => (taxonsTag ? '-60px' : '0px')};
  width: 100%;
  flex-direction: row;
  ${props => config(props).media['sm']`
    margin-bottom: ${({taxonsTag}) => (taxonsTag ? '30px' : '-55px')};
  `}
  h3 {
    font-size: 24px;
    margin-left: 22px;
  }
  .skeletonStyle {
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 200px;
    height: 200px;
    ${props => config(props).media['sm']`
      width: 220px;
      height: 220px;
    `}
    ${props => config(props).media['lg']`
      width: 280px;
      height: 280px;
    `}
    ${props => config(props).media['xl']`
      width: 380px;
      height: 380px;
    `}
  }
  .empty-state {
    width: 20%;
    margin-top: 30px;
  }
  h2 {
    color: ${({theme}) => theme.colors.secondary};
    margin: 30px auto;
  }
`;

export const StyledSlider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: ${({taxonsTag}) => (taxonsTag ? '40px' : '0px')};
  ${props => config(props).media['sm']`
    margin-top: ${({taxonsTag}) => (taxonsTag ? '0px' : '0px')};
    margin-bottom: ${({taxonsTag}) => (taxonsTag ? '-60px' : '0px')};
    height: ${({taxonsTag}) => (taxonsTag ? '110%' : '100%')};
    padding: 0px;
  `}
  :hover {
    cursor: pointer;
  }
  img {
    height: 50px;
  }

  .slick-slider {
    margin: 0 auto;
    padding: 0px;
    width: 100%;
    ${props => config(props).media['xl']`
      width: 130%;
    `}
    .slick-center {
      transform: scale(1.3);
      transition: transform 1s;
    }
    .slick-list {
      height: 100%;
      width: 100%;
      align-items: center;
      .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          margin: ${({taxonsTag}) => (taxonsTag ? '30px' : '0px')};
        }
      }
    }
    button.slick-next,
    button.slick-prev {
      &::before {
        display: flex;
        background: url(${arrowBotton}) center center no-repeat;
        content: ' ';
        width: 30px;
        height: 30px;
        ${props => config(props).media['sm']`
          width: 27px;
          height: 60px;
        `}
      }
    }
    button.slick-next {
      &::before {
        transform: rotate(180deg);
      }
    }
    .slick-dots button:before {
      font-size: 14px;
    }
  }
`;
