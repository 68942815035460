import React from 'react';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';

import StyledSlider from './styles';

const Slider = ({image, title, description, onClick, tag}) => {
  return (
    <StyledSlider
      divider={description}
      tag={tag}
      onClick={onClick ? onClick : () => navigate('/weekly-arrivals')}
    >
      <div className="container-banner">
        <div className="container-banner-image">
          <img src={image && image} />
        </div>
        {tag !== 'lookbook-banner' && (
          <div className="container-banner-info">
            <Heading level="2" as="h2" color="white">
              {title}
            </Heading>
            {title && <div className="line" />}
            <Text level="5" color="white">
              {description}
            </Text>
          </div>
        )}
      </div>
    </StyledSlider>
  );
};

export default Slider;

Slider.propTypes = {
  image: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  titleButton: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.string,
};
