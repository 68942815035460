import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const ProductStyle = styled.div`
  width: 98%;
  margin: 0 auto;
  .wrap__cards {
    max-height: 500px;
    display: flex;
    flex-basis: 50%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    ${props => config(props).media['sm']`
      flex-basis: 15%;
    `}
    ${props => config(props).media['md']`
      flex-basis: 15%;
    `}
  }
  h3 {
    display: flex;
    padding: 40px 16px 40px;
    font-size: 24px;
  }
  .styles__StyledButtonProduct-iZSIYH {
    display: none;

    ${props => config(props).media['md']`
     display: initial;
  `}
  }
  .buttonMobile {
    width: 320px;
    height: 30px;
    background-color: ${({theme}) => theme.colors.primary2};
    padding: 0;
    border: none;
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 10px;
    outline: none;
    color: white;
    cursor: pointer;
    p {
      color: white;
      font-weight: 600;
      font-size: 13px;
    }

    &:hover {
      background-color: ${({theme}) => theme.colors.primary3};

      p {
        color: white;
      }
    }

    &:active {
      outline: none;
      background-color: #c29f5e;
      p {
        color: white;
      }
    }
    ${props => config(props).media['md']`
     display: none;
  `}
  }
  .skeletonProductStyle {
    border-radius: 20px;
    margin-right: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    ${props => config(props).media['md']`
      width: 207px;
      height: 207px;
    `}
    ${props => config(props).media['lg']`
      width: 307px;
      height: 307px;
    `}
  }
  .EmptyState {
    width: 20%;
    margin-bottom: 30px;
  }
  h4 {
    color: ${({theme}) => theme.colors.secondary};
    margin: 30px auto;
  }
`;
