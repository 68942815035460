import React from 'react';
import SliderStatic from './sliderStatic';
import {Col, Row} from 'react-awesome-styled-grid';
import {Heading} from 'styled-typography';
import {CollectionsStyled} from './styles';
import CardCollections from '../core/CardCollections';
import {graphql, useStaticQuery} from 'gatsby';
import {findSection} from '../../utils/staticContent';

const CollectionQuery = graphql`
  query CollectionCategory {
    contentfulPages(internalName: {eq: "Home"}) {
      id
      pageName
      topSection {
        ... on ContentfulBannerImage {
          id
          image {
            publicUrl
            mimeType
            filename
          }
          mobileImage {
            publicUrl
            mimeType
            filename
          }
          url
          internalName
        }
      }
    }
  }
`;
const Collections = () => {
  const {
    contentfulPages: {topSection},
  } = useStaticQuery(CollectionQuery);
  const leftSection = findSection(
    'home-makers-collection-left-banner',
    topSection
  );
  const rightSection = findSection('home-makers-collection-right', topSection);

  return (
    <CollectionsStyled>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            Maker’s collections
          </Heading>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={3.5} sm={7} md={4.5} lg={7} xl={6} align="center">
          <SliderStatic banner={leftSection} />
        </Col>
        <Col xs={3.5} sm={7} md={4.5} lg={4} xl={3} align="center">
          <CardCollections
            background={rightSection?.image.publicUrl}
          ></CardCollections>
        </Col>
      </Row>
    </CollectionsStyled>
  );
};

export default Collections;
