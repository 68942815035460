import React from 'react';

import {Col, Hidden, Row} from 'react-awesome-styled-grid';
import {CategoryStyle} from './styles';
import {Heading} from 'styled-typography';
import Skeleton from 'react-loading-skeleton';

const SkeletonCategory = () => {
  return (
    <CategoryStyle>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            Explore Our Categories
          </Heading>
        </Col>
      </Row>
      <Row justify="center">
        <Col align="center">
          <Hidden xs sm>
            <Skeleton className="skeletonStyle" circle={true} count={5} />
          </Hidden>
          <Hidden xs md lg xl>
            <Skeleton className="skeletonStyle" circle={false} count={4} />
          </Hidden>
          <Hidden sm md lg xl>
            <Skeleton className="skeletonStyle" circle={false} count={2} />
          </Hidden>
        </Col>
      </Row>
    </CategoryStyle>
  );
};

export default SkeletonCategory;
