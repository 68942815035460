import React from 'react';
import {Col, Hidden, Row} from 'react-awesome-styled-grid';
import {Heading} from 'styled-typography';
import {ProductStyle} from './styles';

import Skeleton from 'react-loading-skeleton';
const SkeletonProducts = () => {
  return (
    <ProductStyle>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            Trending Products
          </Heading>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <Hidden xs>
            <Skeleton className="skeletonProductStyle" count={4} />
          </Hidden>
          <Hidden sm md lg xl>
            <Skeleton className="skeletonProductStyle" count={2} />
          </Hidden>
        </Col>
      </Row>
    </ProductStyle>
  );
};

export default SkeletonProducts;
